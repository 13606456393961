
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatSplittedNote1(isListView, note, noteIndex, splittedNote, splittedNoteIndex) {
                    return _createElement('ul', {
                        'className': 'fitment-notes',
                        'key': splittedNote
                    }, _createElement('li', {}, splittedNote));
                }
                function repeatNote2(isListView, note, noteIndex) {
                    return [_map(note.replace('Drilling Required - No\n', '').split('\n'), repeatSplittedNote1.bind(this, isListView, note, noteIndex))];
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function scopeIsListView4() {
                    var isListView = this.view === '1';
                    return _createElement('li', { 'className': 'product' }, isListView ? _createElement('h4', {
                        'className': 'card-title',
                        'key': '76'
                    }, _createElement('a', Object.assign({}, {
                        'href': this.custom_url,
                        'aria-label': 'product title'
                    }, { dangerouslySetInnerHTML: { __html: this.name } }))) : null, _createElement('article', {
                        'className': 'card',
                        'data-product-id': this.id
                    }, _createElement.apply(this, [
                        'div',
                        { 'className': 'card-figure' },
                        _createElement('div', { 'className': 'themevale_badges' }, !this.out_of_stock && this.stock && (this.stock?.includes('Include Special Order') && !this.stock?.includes('In-stock Only')) ? _createElement('div', {
                            'className': 'specialOrder-badge',
                            'key': '375'
                        }, '\n          Special Order\n        ') : null, this.Universal ? _createElement('div', {
                            'className': 'themevale_badge',
                            'key': '633'
                        }, _createElement('span', { 'className': 'text' }, 'Universal')) : null),
                        _createElement('a', {
                            'href': this.custom_url,
                            'className': 'product-link ' + (this.images_image_thumbnail2 ? 'image-swap' : ''),
                            'aria-label': 'product link'
                        }, _createElement('img', {
                            'className': (this.images_image_thumbnail2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.images_image_thumbnail1)),
                            'data-src': this.images_image_thumbnail1,
                            'data-sizes': 'auto',
                            'alt': this.removeHTML(this.name),
                            'onError': this.onImageError
                        }), this.images_image_thumbnail2 ? _createElement('img', {
                            'className': 'image-two lazyautosizes lazyloaded',
                            'src': this.resizeImage(this.images_image_thumbnail2),
                            'data-src': this.images_image_thumbnail2,
                            'data-sizes': 'auto',
                            'alt': this.removeHTML(this.name),
                            'onError': e => this.onImageError(e, 'images_image_thumbnail2'),
                            'key': '1241'
                        }) : null),
                        isListView && this.Notes?.length ? _map(this.Notes, repeatNote2.bind(this, isListView)) : null,
                        !isListView ? _createElement('div', {
                            'className': 'card-buttonGroup',
                            'key': '1913'
                        }, _createElement('a', {
                            'aria-label': 'quickview',
                            'className': 'card-button-quickview quickview',
                            'data-product-id': this.id
                        }, _createElement('svg', { 'title': 'icon eye' }, _createElement('use', { 'xlinkHref': '#icon-eye' })), _createElement('span', { 'className': 'text' }, 'quick view')), _createElement('a', {
                            'href': '/wishlist.php?action=add&product_id=' + this.id,
                            'aria-label': 'wishlist',
                            'className': 'card-button-wishlist',
                            'title': 'Add to Wish List'
                        }, _createElement('svg', { 'title': 'icon heart' }, _createElement('use', { 'xlinkHref': '#icon-heart' })))) : null,
                        !isListView ? _createElement('div', {
                            'className': 'card-figcaption',
                            'key': '2490'
                        }, !this.out_of_stock ? [
                            !this.has_options ? _createElement('a', {
                                'href': '/cart.php?action=add&product_id=' + this.id,
                                'aria-label': 'add to cart',
                                'className': 'button card-figcaption-button themevale_btnATC',
                                'data-product-id': this.id,
                                'key': '25481'
                            }, '\n            Add to Cart\n          ') : null,
                            this.has_options ? _createElement('a', {
                                'href': this.custom_url,
                                'data-event-type': 'product-click',
                                'className': 'button card-figcaption-button',
                                'data-product-id': this.id,
                                'key': '25483'
                            }, '\n            Choose Options\n          ') : null
                        ] : null, this.out_of_stock ? _createElement('div', {
                            'className': 'button card-figcaption-button',
                            'disabled': true,
                            'key': '3062'
                        }, 'Out of stock') : null) : null
                    ]), _createElement('div', { 'className': 'card-body' }, _createElement('div', { 'className': 'card-detail' }, !isListView ? _createElement('p', mergeProps({
                        'className': 'card-brand',
                        'data-test-info-type': 'brandName',
                        'key': '3254'
                    }, { dangerouslySetInnerHTML: { __html: this.brand_name } })) : null, !isListView ? _createElement('h4', {
                        'className': 'card-title',
                        'key': '3403'
                    }, _createElement('a', Object.assign({}, {
                        'href': this.custom_url,
                        'aria-label': 'product title'
                    }, { dangerouslySetInnerHTML: { __html: this.name } }))) : null, _createElement('div', { 'className': 'custom-fields' }, this.Class ? _createElement('p', { 'key': '3633' }, _createElement('span', {}, 'Class:'), this.Class) : null, this.Receiver_Size ? _createElement('p', { 'key': '3701' }, _createElement('span', {}, 'Receiver Size:'), this.Receiver_Size) : null, this.Mount_Type ? _createElement('p', { 'key': '3793' }, _createElement('span', {}, 'Mount Type:'), this.Mount_Type) : null, this.Attachment_Location ? _createElement('p', { 'key': '3876' }, _createElement('span', {}, 'Attachment Location:'), this.Attachment_Location) : null, this.Weight_Capacity ? _createElement('p', { 'key': '3986' }, _createElement('span', {}, 'Weight Capacity:'), this.Weight_Capacity) : null, this.Tongue_Weight ? _createElement('p', { 'key': '4084' }, _createElement('span', {}, 'Tongue Weight:'), this.Tongue_Weight) : null, this.GTW ? _createElement('p', { 'key': '4176' }, _createElement('span', {}, 'Gross Trailer Weight:'), this.GTW) : null, this.Handle_Type ? _createElement('p', { 'key': '4255' }, _createElement('span', {}, 'Handle Type:'), this.Handle_Type) : null, this.Includes ? _createElement('p', { 'key': '4341' }, _createElement('span', {}, 'Includes:'), this.Includes) : null), !isListView ? _createElement('div', {
                        'className': 'card-price-wrapper',
                        'key': '4432'
                    }, _createElement('div', {
                        'className': 'card-price',
                        'data-test-info-type': 'price'
                    }, this.retail_price > this.price ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                        'key': '4560'
                    }, _createElement('span', {
                        'data-product-rrp-price-without-tax': true,
                        'className': 'price price--rrp'
                    }, '\n                MSRP: ', this.formatPrice(this.retail_price), '\n              ')) : null, this.on_sale ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                        'key': '4875'
                    }, _createElement('span', {
                        'data-product-rrp-price-without-tax': true,
                        'className': 'price price--non-sale'
                    }, '\n                ', this.formatPrice(this.regular_price), '\n              ')) : null, !this.retail_price && !this.regular_price || this.retail_price <= this.price || this.regular_price <= this.price ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax non-sale-price--withoutTax price-none',
                        'key': '5174'
                    }, _createElement('span', {
                        'data-product-non-sale-price-without-tax': true,
                        'className': 'price price--non-sale'
                    })) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                        'data-product-price-without-tax': true,
                        'className': 'price price--withoutTax'
                    }, '\n                ', this.formatPrice(this.price), '\n              '))), this.price ? _createElement('p', {
                        'className': 'affirm-as-low-as',
                        'data-amount': this.price * 100,
                        'data-affirm-type': 'logo',
                        'data-affirm-color': 'blue',
                        'key': '5813'
                    }) : null) : null), isListView ? _createElement('div', {
                        'className': 'card-actions',
                        'key': '5978'
                    }, _createElement('div', { 'className': 'card-info' }, _createElement('div', { 'className': 'card-brand-info' }, this.brand_name ? _createElement('a', {
                        'href': this.brand_url,
                        'key': '6108'
                    }, _createElement('div', { 'className': 'product-brand' }, this.brand_image ? _createElement('img', {
                        'className': 'card-image-brand',
                        'src': this.imageOrDefault(this.brand_image),
                        'alt': this.removeHTML(this.title),
                        'onError': this.onImageError,
                        'key': '6218'
                    }) : null, !this.brand_image ? _createElement('span', Object.assign({}, { 'key': '6403' }, { dangerouslySetInnerHTML: { __html: this.brand_name } })) : null)) : null, this.Made_In ? _createElement('div', {
                        'className': 'made-in',
                        'key': '6552'
                    }, this.Made_In === 'US' ? _createElement('img', {
                        'src': 'https://img.towuniverse.com/madeinusa.png',
                        'width': '100',
                        'key': '6609'
                    }) : null, this.Made_In === 'USMX' ? _createElement('img', {
                        'src': 'https://img.towuniverse.com/usmexico.png',
                        'width': '200',
                        'key': '6730'
                    }) : null) : null), _createElement('div', { 'className': 'card-price-wrapper' }, _createElement('div', {
                        'className': 'card-price',
                        'data-test-info-type': 'price'
                    }, this.retail_price > this.price ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax rrp-price--withoutTax',
                        'key': '6997'
                    }, _createElement('span', {
                        'data-product-rrp-price-without-tax': true,
                        'className': 'price price--rrp'
                    }, '\n                  MSRP: ', this.formatPrice(this.retail_price), '\n                ')) : null, this.on_sale ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax non-sale-price--withoutTax',
                        'key': '7322'
                    }, _createElement('span', {
                        'data-product-rrp-price-without-tax': true,
                        'className': 'price price--non-sale'
                    }, '\n                  ', this.formatPrice(this.regular_price), '\n                ')) : null, !this.retail_price && !this.regular_price || this.retail_price <= this.price || this.regular_price <= this.price ? _createElement('div', {
                        'className': 'price-section price-section--withoutTax non-sale-price--withoutTax price-none',
                        'key': '7631'
                    }, _createElement('span', {
                        'data-product-non-sale-price-without-tax': true,
                        'className': 'price price--non-sale'
                    })) : null, _createElement('div', { 'className': 'price-section price-section--withoutTax' }, _createElement('span', {
                        'data-product-price-without-tax': true,
                        'className': 'price price--withoutTax'
                    }, '\n                  ', this.formatPrice(this.price), '\n                ')), this.retail_price > this.price ? _createElement('div', {
                        'className': 'price-discount',
                        'key': '8275'
                    }, '\n                (You save: ', this.formatPrice(this.retail_price - this.price), ')\n              ') : null), !this.out_of_stock ? _createElement('div', {
                        'className': 'in-stock',
                        'key': '8478'
                    }, _createElement('svg', {
                        'className': 'icon',
                        'title': 'icon check'
                    }, _createElement('use', { 'xlinkHref': '#icon-check' })), ' In-Stock\n            ') : null)), _createElement('div', { 'className': 'card-figcaption' }, !this.out_of_stock ? [
                        !this.has_options ? _createElement('a', {
                            'href': '/cart.php?action=add&product_id=' + this.id,
                            'aria-label': 'add to cart',
                            'className': 'button card-figcaption-button themevale_btnATC',
                            'data-product-id': this.id,
                            'key': '87251'
                        }, '\n              Add to Cart\n            ') : null,
                        this.has_options ? _createElement('a', {
                            'href': this.custom_url,
                            'data-event-type': 'product-click',
                            'className': 'button card-figcaption-button',
                            'data-product-id': this.id,
                            'key': '87253'
                        }, '\n              Choose Options\n            ') : null
                    ] : null, this.out_of_stock ? _createElement('div', {
                        'className': 'button card-figcaption-button',
                        'disabled': true,
                        'key': '9255'
                    }, 'Out of stock') : null), this.Notes?.length && this.Notes[0].includes('Drilling Required - No') ? _createElement('div', {
                        'className': 'no-drilling',
                        'key': '9379'
                    }, _createElement('img', { 'src': 'https://cdn11.bigcommerce.com/s-uahhm6eqvm/images/stencil/original/image-manager/no-drilling-required.png' }), '\n          NO DRILLING REQUIRED\n        ') : null) : null)));
                }
                return scopeIsListView4.apply(this, []);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products cm_SearchResult' }, _createElement.apply(this, [
        'div',
        { 'className': 'productGrid productGrid--maxCol2 cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []