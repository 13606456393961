export default {
  SEARCH_BOX_PLACEHOLDER: 'Search by SKU, VIN or Keyword',

  CHANGE_TEXT: 'Change Vehicle',
  VERIFY_FITMENT_TITLE: 'ENTER FITMENT',
  VERIFY_FITMENT_TEXT: 'See if this product fits your vehicle',
  VERIFY_FITMENT_FITS: 'FITS YOUR VEHICLE',
  VERIFY_FITMENT_NOT_FIT: "DOESN'T FIT YOUR VEHICLE",
  FITMENT_UNIVERSAL_TEXT: 'Not vehicle specific.',
  VERIFY_FITMENT_COMPATIBLE_PARTS: 'View fitting products',

  VERIFY_FITMENT_ICON: `
  <rt-virtual rt-if="this.fits === 'yes' || this.fits === 'universal'" template="icons/check" />
  <rt-virtual rt-if="this.fits === 'no'" template="icons/times" />
  <rt-virtual rt-if="this.fits === undefined" template="icons/questionmark" />
  <rt-virtual rt-if="this.fits === 'aggressive'" template="icons/exclamationmark" />`,
};
