import bigcommerceSparkDefaults from '../bigcommerce-spark/config.js';

const { baseFields } = bigcommerceSparkDefaults.fitmentSearch;

bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'VerifyFitment').isAlwaysColumnLayout = true;
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'FacetCategoryTiles').isMultiLevel = true;
bigcommerceSparkDefaults.Widgets.find((w) => w.name === 'HomeVehicleWidget_custom').columnBreakpoint = 700;

function ChooseDefaultView() {
  const breadcrumbs = document.querySelector('.breadcrumbs');
  if (breadcrumbs && breadcrumbs.textContent.includes('Trailer Hitch')) {
    return '1';
  }
  return '3';
}

const fieldsForRelated = ['category'];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

export default {
  includes: ['bigcommerce-spark'],
  ...bigcommerceSparkDefaults,
  defaultView: ChooseDefaultView(),
  Widgets: [
    ...bigcommerceSparkDefaults.Widgets.filter((w) => !['Garage', 'SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFields,
      },
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: 'body.page-type-category .themevale_subCategory',
      template: 'facetTiles/subtitles',
      facetField: 'category',
    },
    {
      name: 'RelatedItems',
      location: {
        replace: 'body.page-type-product #productRelated-content',
        class: 'toggle-content is-open',
      },
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '#menu',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'SingleVehicleGarageMobile',
      type: 'SingleVehicleGarage',
      location: {
        insertAfter: '.themevale_middleHeader',
        class: 'cm_single-vehicle-garage__container cm_desktop',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
  ],
};
